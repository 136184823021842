import './QplusNotification.scss';
import qplus1 from '../../../assets/qplus1.jpeg';
import qplus2 from '../../../assets/qplus2.jpeg';
import frimi from '../../../assets/frimi_logo.png';
export const QplusNotification = () => {
  return (
    <div>
       
      <div className="row">
        <div className="col-6">
          <img src={qplus1} className="img-fluid" />
        </div>

        <div className="col-6">
          <img src={qplus2} className="img-fluid" />
        </div>
      </div>
    </div>
  );
};
